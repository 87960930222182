import React, { useState, useEffect } from "react";
import "../css/App.css";
import "../css/ourwork.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import ReCAPTCHA from "react-google-recaptcha";
import { MdArrowOutward } from "react-icons/md";
import { Carousel, Modal, Image, Collapse, Row, Col } from "antd";
import VideoJS from "video.js";
import "video.js/dist/video-js.css";

const { Panel } = Collapse;

function Home() {
  const Poductionimages = [
    "/ourwork/Production/paradiso/paradisoII.webp",
    "/ourwork/Production/paradiso/paradisoXI.webp",
    "/ourwork/Production/paradiso/paradisoXIII.webp",
    "/ourwork/Production/paradiso/paradisoXIV.webp",
    "/ourwork/Production/paradiso/paradisoXIX.webp",
    "/ourwork/Production/paradiso/paradisoXVII.webp",
    "/ourwork/Production/paradiso/paradisoXX.webp",
  ];

  const image = [
    "/home/photo/nofame-photo-main (1).webp",
    "/home/photo/nofame-photo-main (2).webp",
    "/home/photo/nofame-photo-main (3).webp",
    "/home/photo/nofame-photo-main (4).webp",
    "/home/photo/nofame-photo-main (5).webp",
    "/home/photo/nofame-photo-main (6).webp",
    "/home/photo/nofame-photo-main (7).webp",
    "/home/photo/nofame-photo-main (8).webp",
    "/home/photo/nofame-photo-main (9).webp",
    "/home/photo/nofame-photo-main (10).webp",
  ];

  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % image.length);
    }, 2000);
    window.scrollTo(0, 0);
    return () => clearInterval(interval);
  }, []);

  const [isModalOpenPic, setIsModalOpenPic] = useState(false);
  const [TitlePic, setTitlePic] = useState("")
  const showModalPic = (title) => {
    setIsModalOpenPic(true);
    setTitlePic(title)
  };
  const handleCancelPic = () => {
    setIsModalOpenPic(false);
  };

  const [isModalOpenVideo, setIsModalOpenVideo] = useState(false);
  const showModalVideo = () => {
    setIsModalOpenVideo(true);
  };
  const handleCancelVideo = () => {
    setIsModalOpenVideo(false);
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <video className="background-video" autoPlay loop muted>
        <source src="/nofame-main.mp4" />
      </video>

      <div className="Home">
        <div className="section-we">
          <h1>No Fame Agency</h1>
          <hr></hr>
          <h3>  Photo   •   Video   •   Event Production   •   Storytelling</h3>
        </div>

        <div className="Photography">
          <img
            src={image[currentIndex]}
            alt="No fame Photography"
            className="Photography-img"
          />
          <div className="Photography-contents">
            <div className="Photography-left">
              <div className="Photography-header">
                <h1>Photography</h1>
                <p>
                  At No Fame, we offer custom photography services that capture your brand, event, or personal  moments with attention to detail and creativity.<br /> Whether it's dynamic visuals for your business or  exceptional event coverage, we ensure your content makes the impact it deserves.
                </p>
              </div>
              
              <div className="Photography-text">
                <div className="list-table">

                  <div className="list-row" onClick={showModalPic}>
                    <div className="list-header">
                      <h2>Event</h2>
                    </div>
                    <p>From festivals to weddings and business events, we capture the moments that matter most to you.</p>
                  </div>

                  <div className="list-row" onClick={showModalPic}>
                    <div className="list-header">
                      <h2>Travel</h2>
                    </div>
                    <p>Documenting your journeys, landscapes, and cultures. We go places!</p>
                  </div>

                  <div className="list-row" onClick={showModalPic}>
                    <div className="list-header">
                      <h2>Corporate </h2>
                    </div>
                    <p>From corporate presentations to branding visuals, we help showcase your business in the best light.</p>
                  </div>

                  <div className="list-row" onClick={showModalPic}>
                    <div className="list-header">
                      <h2>Creative Concepts & Styling</h2>
                    </div>
                    <p>We collaborate with you to craft visual stories that bring your ideas to life.</p>
                  </div>
                </div>

                <div className="more-section-photo">
                  <Link to="/Photography">
                    —  see more —
                  </Link>
                </div>
              </div>
            </div>

            <div className="Photography-right">
            </div>

          </div>
        </div>

        <div className="Videography">
          {/* <div className="Videography-video"> */}
          <video className="Videography-video" autoPlay loop muted>
            <source src="/home/videology/mazing_thailand-2.mp4" />
          </video>

          <div className="Videography-contents">
            <div className="Videography-left">
              <div className="Videography-header">
                <h1>Videography</h1>
              </div>
              <div className="Videography-text">
                <p>
                  At No Fame, we specialize in creating high-impact videos that tell your story with precision, creativity,  and a cinematic touch. Whether you're hosting a festival, embarking on a travel adventure, organizing  a business event, or looking to elevate your brand through powerful visual storytelling, our team is  here to bring your ideas to life.
                </p>
                <div className="more-section">
                  <Link to="/Videography">
                    —  see more —
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Videography-bye" style={{ color: "white", textAlign: "center", marginTop: 0 }}>
          <h3> Let us help you tell your story – beautifully and authentically</h3>
        </div>

        <div className="EventProduction">
          <div className="EventProduction-header">
            <h1>Event Production</h1>
          </div>
          <div className="production-section">
            <img src="/logo/paradiso_yellow.webp" alt="paradiso carnival" />
            <video className="background-video-ourwork" autoPlay
              loop
              muted
              playsInline
              controlsList="nodownload"
              onContextMenu={(e) => e.preventDefault()}
              preload="auto"
              title="Paradiso Carnival 2025 - The Best Music Festival in Bangkok"
            >
              <source src="/ourwork/Production/paradiso/video/paradiso-website.mp4" type="video/mp4" />
            </video>
            <Carousel
              autoplay
              dots={false}
              infinite
              slidesToShow={5}
              responsive={[
                { breakpoint: 1280, settings: { slidesToShow: 4 } },
                { breakpoint: 1024, settings: { slidesToShow: 3 } },
                { breakpoint: 768, settings: { slidesToShow: 2 } },
                { breakpoint: 480, settings: { slidesToShow: 1 } },
              ]}
            >
              {Poductionimages.map((srcproduction, indexproduction) => (
                <div key={indexproduction} className="carousel-item">
                  <img
                    src={srcproduction}
                    alt={`Slide ${indexproduction + 1}`}
                    className="carousel-image"
                    style={{ width: "100%", height: "200px", objectFit: "cover" }}
                  />
                </div>
              ))}
            </Carousel>

            <div className="text-production">
              <p>
                <b style={{ fontWeight: "bold", textDecoration: "none" }}>NF Entertainment</b> is the creator of Paradiso Carnival, a one-of-a-kind festival that blends music, art, and storytelling into an immersive experience. As the driving force behind the concept, we are expanding <b>Paradiso Carnival to new countries</b>, bringing its unique energy and creative vision to audiences <b>worldwide.</b>
                {" "}<a
                  href="https://www.paradisocarnival.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="more-link"
                >
                  More <MdArrowOutward className="more-icon" />
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="US">
          <img src="/home/photo/BTS.jpg" alt="about us NO FAME" className="US-image"></img>
          <div className="US-contents">
            <div>
              <div className="US-header">
                <h2>This Is Us – The Creative Team Behind No Fame</h2>
              </div>
              <div className="US-text">
                <p>
                  At No Fame, we are more than just a creative agency—we are a team of passionate  storytellers, artists, and producers committed to bringing your vision to life. Whether  it's stunning photography, cinematic videography, or seamless event production, we  specialize in creating experiences that inspire and leave a lasting impression.
                </p>
              </div>
              <div className="US-text-sub">
                <h2>Our Promise to You</h2>
                <p>We promise to provide you with creative solutions,
                  top-tier service, and a team that’s  dedicated to making your project a success.
                </p>
              </div>
            </div>

          </div>
        </div>

        <div className="section-contact">
          <img className="section-contact-pic" src="/home/photo/contact-us.jpg" alt="No fame" />

          <div className="Community">
            <h1>Giving back to the community</h1>
            <div className="Community-contents">
              <h2>
                NF Entertainment Thai Team Gives Back to the Community
              </h2>
              <br />
              <p>
                At NF Entertainment, we believe in using our platform not only to create unforgettable experiences but also to make a meaningful impact on the communities around us. With this spirit, our Thai team has come together to support underprivileged children by donating toys and decorative props from Paradiso Carnival to the Foundation For Slum Child Care.
                {" "}
                <Link to="/blog/donation-2025"
                  rel="noreferrer"
                  className="more-link-commu"
                >
                  More <MdArrowOutward className="more-icon-commu" />
                </Link>

              </p>
            </div>
          </div>

          <div className="contact-form">
            <div className="contact-text-first">
              <img src="/logo/logo.ico" alt="No fame Logo" />
              <h2>Let's Create Together</h2>
            </div>

            <div className="contact-form-container">
              <form
                action="https://usebasin.com/f/4ea50500a8b3" method="POST" enctype="multipart/form-data" id="form"
                className="contact-form-2"
              >
                <div className="form-group">
                  <input type="text" name="name" required placeholder="Name*" />
                </div>

                <div className="form-group">
                  <input type="email" name="email" required placeholder="Email*" />
                </div>

                <div className="form-group">
                  <textarea name="message" rows="5" required placeholder="Message*"></textarea>
                </div>

                <div className="form-group">
                  <ReCAPTCHA
                    className="captcha"
                    sitekey="6LdNw_EqAAAAAMqVjkgT9P3Qbs2VSsCNKFQ_lrQh"
                    onChange={handleCaptchaChange}
                  />
                </div>
                <br />

                <button type="submit" className="send-button">
                  — SEND —
                </button>
              </form>
            </div>

          </div>

        </div>
      </div>

    </motion.div>
  );
}

export default Home;
